import request from '@/utils/request'


export function listUser(query) {
  return request({
    url: '/user/list',
    method: 'get',
    params: query
  })
}
export function companyAdd(data) {
  return request({
    url: '/user/company-add',
    method: 'post',
    data: data
  });
}
export function companySync(data) {
  return request({
    url: '/user/company-sync',
    method: 'post',
    data: data
  });
}
  export function adminSet(data) {
    return request({
      url: '/user/admin-set',
      method: 'post',
      data: data
    });
  }
  export function adminCancel(data) {
    return request({
      url: '/user/admin-cancel',
      method: 'post',
      data: data
    });
  }
