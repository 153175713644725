<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="昵称" prop="nickname">
                <a-input v-model="queryParam.nickname" placeholder="请输入昵称" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="手机号" prop="mobile">
                <a-input v-model="queryParam.mobile" placeholder="请输入手机号" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="是否管理员" prop="isAdmin">
                <a-select placeholder="请选择是否管理员" style="width: 100%" v-model="queryParam.isAdmin" allowClear>
                  <a-select-option v-for="(item, index) in this.customDict.TFEnum" :value="item.type"
                                   :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
<!--            <a-col :md="6" :sm="24">
              <a-form-item label="性别" prop="sex">
                <a-select placeholder="请选择性别" style="width: 100%" v-model="queryParam.sex" allowClear>
                  <a-select-option v-for="(item, index) in this.customDict.UserSexEnum" :value="item.type"
                                   :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>-->
            <a-col :md="!advanced && 6 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary"  @click="$refs.createForm.handleAdd()" v-hasPermi="['user:user:company-add']">
          <a-icon type="plus" />添加企业账号
        </a-button>
        <a-button type="primary"  @click="companySync()" v-hasPermi="['user:user:company-sync']">
          <a-icon type="plus" />同步企业账号
        </a-button>
        <a-button type="primary" :disabled="multiple" @click="adminSet( ids)" v-hasPermi="['user:user:admin-set']">
          <a-icon type="edit" />设置管理员
        </a-button>
        <a-button type="primary" :disabled="multiple" @click="adminCancel( ids)" v-hasPermi="['user:user:admin-cancel']">
          <a-icon type="edit" />取消管理员
        </a-button>
        <a-button type="primary" @click="handleExport" v-hasPermi="['user:user:export']">
          <a-icon type="download" />导出
        </a-button>
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <audit-form
        ref="auditForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="isNotice" slot-scope="text, record">
            <!-- 重写自定义了个字典组件-->
           <custom-dict-tag :options="customDict.TFEnum" :value="record.isNotice"/>
        </span>
        <span slot="isAdmin" slot-scope="text, record">
            <!-- 重写自定义了个字典组件-->
           <custom-dict-tag :options="customDict.TFEnum" :value="record.isAdmin"/>
        </span>
        <span slot="sex" slot-scope="text, record">
            <!-- 重写自定义了个字典组件-->
           <custom-dict-tag :options="customDict.UserSexEnum" :value="record.sex"/>
        </span>
        <template slot="avatar" slot-scope="text, record">
          <div>
            <img v-if="record.avatar" :src="record.avatar"  style="width:60px;height:62px;" preview="handlePreview"/>
          </div>
        </template>
        <span slot="operation" slot-scope="text, record">
<!--          <a-divider type="vertical" v-hasPermi="['user:auth/personal:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-if="record.auditStatus!==20" v-hasPermi="['user:auth/personal:edit']">
            <a-icon type="edit" />审核
          </a>-->
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :pageSizeOptions="pageSizeOptions"
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import {listUser, adminSet, adminCancel, companySync} from '@/api/user/user'
import CustomDictTag from "@/components/DictCustomTag";
import {mapGetters} from 'vuex'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'AuthPersonal',
  components: {
    CustomDictTag,
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      pageSizeOptions: ['10', '20', '50', '100'],
      selectedRowKeys: [],
      selectedRows: [],
      //预览封面
      previewVisible: false,
      previewAvatar: '',
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        nickname: null,
        status: null,
        mobile: null,
        isNotice: null,
        isAdmin: null,

        idCardImgFace: null,
        idCardImgNational: null,
        auditStatus: null,
        auditReason: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        /*{
          title: '用户id ,主键zb_user.id',
          dataIndex: 'id',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: '昵称',
          dataIndex: 'nickname',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '手机号',
          dataIndex: 'mobile',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '头像',
          dataIndex: 'avatar',
          scopedSlots: { customRender: 'avatar' },
          ellipsis: true,
          align: 'center'
        },
       /* {
          title: '生日',
          dataIndex: 'birthday',
          ellipsis: true,
          align: 'center'
        },*/
   /*     {
          title: '性别',
          dataIndex: 'sex',
          scopedSlots: { customRender: 'sex' },
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: '是否开启推送',
          dataIndex: 'isNotice',
          scopedSlots: { customRender: 'isNotice' },
          align: 'center'
        },
        {
          title: '是否管理员',
          dataIndex: 'isAdmin',
          scopedSlots: { customRender: 'isAdmin' },
          align: 'center'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '备注',
          dataIndex: 'remark',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  methods: {
    /** 查询用户-用户个人认证列表 */
    getList () {
      this.loading = true
      listUser(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    handlePreview(file) {
      this.previewAvatar = file.url || file.preview;
      this.previewVisible = true;
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        realName: undefined,
        contactPhone: undefined,
        idCardNo: undefined,
        idCardImgFace: undefined,
        idCardImgNational: undefined,
        auditStatus: undefined,
        auditReason: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    adminSet (ids) {
      var that = this
      this.$confirm({
        title: '确认设置管理员?',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return adminSet(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '管理员设置成功',
                3
              )
            })
        },
        onCancel () {}
      })

    },
    companySync () {
      var that = this
      this.$confirm({
        title: '确认同步企业信息?',
        onOk () {
          return companySync()
            .then(() => {
              that.getList()
              that.$message.success(
                '同步成功',
                3
              )
            })
        },
      })
    },
    adminCancel (ids) {
      var that = this
      this.$confirm({
        title: '确认取消管理员?',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return adminCancel(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '管理员取消成功',
                3
              )
            })
        },
        onCancel () {}
      })

    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('user/export', {
            ...that.queryParam
          }, `用户-用户_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
