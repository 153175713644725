<template>
  <a-modal width="60%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" :destroyOnClose="true"  @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form"  :label-col="labelCol" :wrapper-col="wrapperCol" :model="form" :rules="rules">
      <a-form-model-item label="企业名称" prop="companyName" >
        <a-input v-model="form.companyName" :maxLength="16" placeholder="请输入企业名称" />
      </a-form-model-item>
      <a-form-model-item label="企业信用代码" prop="companyNo" >
        <a-input v-model="form.companyNo" :maxLength="32" placeholder="请输入企业信用代码" />
      </a-form-model-item>
      <a-form-model-item label="法人姓名" prop="legalName" >
        <a-input v-model="form.legalName" placeholder="请输入法人姓名" />
      </a-form-model-item>
      <a-form-model-item label="法人身份证" prop="legalIdCardNo" >
        <a-input v-model="form.legalIdCardNo" placeholder="请输入法人身份证" />
      </a-form-model-item>
      <a-form-model-item label="负责人" prop="contactName" >
        <a-input v-model="form.contactName" placeholder="请输入负责人" />
      </a-form-model-item>
      <a-form-model-item label="手机号(登录账号)" prop="contactPhone" >
        <a-input v-model="form.contactPhone" placeholder="请输入手机号" />
      </a-form-model-item>
      <a-form-model-item label="密码" prop="password" >
        <a-input v-model="form.password" placeholder="请输入密码" />
      </a-form-model-item>
      <a-form-model-item label="营业执照" prop="certImg">
        <a-upload
          name="certImg"
          listType="picture-card"
          class="avatar-uploader"
          :multiple="false"
          :show-upload-list="false"
          :preview="handlePreview"
          :before-upload="beforeOssUpload"
          :customRequest="coverImgUpload">
          <img
            v-if="form.certImg"
            :src="form.certImg"
            alt="certImg"
            style="height: 140px; width: 140px;"/>
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-modal>
</template>

<script>
import { companyAdd,adminSet,adminCancel } from '@/api/user/user'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import Editor from '@/components/Editor'
import {generateFilePath, uploadObject} from "@/api/tool/alioss";
import {fileUpload} from "@/api/tool/common";

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    Editor,
    CustomDictTag,
  },
  data () {
    return {
      count: 0,
      categorySelected :[],
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      loading: '',
      categoryList:null,
      fileList: [],
      imgCaseList: [],
      // 表单参数
      form: {
        id: null,
        certImg: null,
        companyName: null,
        companyNo: null,
        legalName: null,
        legalIdCardNo: null,
        contactName: null,
        contactPhone: null,
        password: null,
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        companyName: [
          { required: true, message: '企业名称不能为空', trigger: 'blur' }
        ],
        companyNo: [
          { required: true, message: '企业信用代码不能为空', trigger: 'blur' }
        ],
        legalName: [
          { required: true, message: '法人姓名不能为空', trigger: 'blur' }
        ],
        legalIdCardNo: [
          { required: true, message: '法人身份证不能为空', trigger: 'blur' }
        ],
        contactPhone: [
          { required: true, message: '登录账号不能为空', trigger: 'blur' }
        ],
        contactName: [
          { required: true, message: '负责人不能为空', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '密码不能为空', trigger: 'blur' }
        ],

      }
    }
  },
  filters: {
  },
  created () {
    this.getCategoryList()
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
      this.reset()
    },
    addTime() {
      const index = this.form.specList.length+1;
      const newData = {
        index: index,
        spec: null,
        stock: 0
      };
      this.form.specList.push(newData);
      this.count = index;
    },
    /**
     * 删除等级
     */
    delTime(info) {
      this.form.specList = this.form.specList.filter(item => item.index !== info.index);
    },

    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    getCategoryList () {
      advertCategory().then(response => {
        this.categoryList = response.data
      })
    },
    onCategoryChange (value, selectedOptions) {
      this.form.categoryId = value[1]
    },
    startTimeChange(date, dateString){
      this.form.startTime = dateString
    },
    endTimeChange(date, dateString){
      this.form.endTime = dateString
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.fileList= []
      this.form = {
        id: null,
        certImg: null,
        companyName: null,
        companyNo: null,
        specList: [],
        categoryId: null,
        advertImgList: [],
        advertDesc: null,
        imgCaseList: [],
        advertImg: null,
        legalName: null,
        monthAmount: null,
        contactPhone: null,
        rule: null,
        isSale: null,
        createTime: null,
      }
    },
    // oss 上传开始
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    // 上传之前校验
    beforeOssUpload(file) {
      // const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 20
      // if (!isJPG) {
      //     this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 20MB!')
      }
      return isLt2M
    },
    // 上传覆盖默认的上传行为
    coverImgUpload(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'user'
      const url = _this.uploadAliYunOss(uploadFile.name, prefix)
      /*_this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          _this.form.certImg = res
          _this.$message.success('上传成功')
        })
      })*/
      const formData = new FormData()
      formData.append('file', uploadFile, url)
      _this.$nextTick(() => {
        fileUpload(formData).then(res => {
          _this.form.certImg = res.data.fullPath
          _this.$message.success('上传成功')
        })
      })
    },
    /**
     * 文件上传到oss返回url地址
     * @param { file } 文件对象
     */
    uploadAliYunOss(fileName, prefix) {
      // 获取文件后缀
      const suffix = fileName.substring(fileName.lastIndexOf('.'))
      // 生成文件名称
      const filePath = generateFilePath(prefix, suffix)
      return filePath
    },
    // oss 上传结束


    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          companyAdd(this.form).then(response => {
            this.$message.success(
              '新增成功',
              3
            )
            this.open = false
            this.$emit('ok')
          }).finally(() => {
            this.submitLoading = false
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>
